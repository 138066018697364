/*eslint-disable*/
export default {
    data() {
        return {
            classes: '',
            btn_id: null,
            paydata: null,
            sendingChoices: false,
            profile: null,
            loading: true,
            candidateCounselor: {},
            cycleId: null,
            days: [],
            selectedDays: [],
            selectedDaysList: [],
            remainCount: 0,
            unfitCounselor: '',
            unfitTime: '',
            todoMessage: '',
            showBuyPackage: false,
            canSelect: false,
            dialogRemainTime: false,
            dialogChangeCounselor: false,
            paymentDialog: false,
            successfulPayment: {
                refId: '',
                trCode: '',
            },
            nextSession: {},
            message: '',
            transactionClass: null,
            transactionRefId: null,
            validOrder: false,
            orderInfo: null,
        }
    },
    mounted() {
    },
    methods: {
        prepareDataForWebengageAndSend(resp) {
            this.orderInfo = resp.data
            if (resp.data.msg == "success payment") {
                let currency = resp.data.currency == 'dollar' ? 'USD' : 'RIAL';
                this.transactionMessage = this.successTitle;
                this.btn_id = 'ga_purchase_successful';
                // this.transactionClass = 'success';
                let order = resp.data.order;
                let conversionRate = resp.data.conversion_rate;
                this.successfulPayment.refId = resp.data.ref_id;


                if (resp.data.newCustomer) {
                    this.classes = 'new-customer';
                }
                // if (window.location.hostname.includes('simiaroom')) {

                let products = [];
                let webengageProducts = [];

                order.products.forEach((item, index) => {

                    webengageProducts[`product_id_${(index + 1)}`] = item.id;
                    webengageProducts[`product_name_${(index + 1)}`] = item.title;
                    webengageProducts[`product_discount_${(index + 1)}`] = parseFloat((item.pivot.discount * conversionRate).toFixed(2));
                    webengageProducts[`product_item_count_${(index + 1)}`] = item.pivot.quantity;
                    webengageProducts[`product_price_${(index + 1)}`] = parseFloat((item.pivot.price * conversionRate).toFixed(2));
                    webengageProducts[`product_session_count_${(index + 1)}`] = item.session_count;

                    products.push({
                        'name': item.title,
                        'id': item.id,
                        'price': item.pivot.price,
                        'brand': 'Simiaroom',
                        'category': 'Counseling',
                        'quantity': item.pivot.quantity
                    });
                });


                let ecommerce = {
                    purchase: {
                        actionField: {
                            id: resp.data.trCode,
                            revenue: order.subtotal //<%=sales.total%>
                        },
                        products
                    }
                }
                // console.log(ecommerce);
                if (order.discount == 0) {
                    ecommerce.purchase.actionField.coupon = '';
                } else {
                    ecommerce.purchase.actionField.coupon = order.discount;
                }
                let webEngageData = {
                    currency: 'dollar',
                    isFirstPurchase: (resp.data.newCustomer == true) ? 'new-customer' : 'old-customer',
                    cartsCount: resp.data.cartsCount,
                    cart_id: resp.data.payment.cart_id,
                    coupon_code: resp.data.coupon_code,
                    amount: parseFloat((resp.data.payment.cart_amount * conversionRate).toFixed(2)),
                    ...webengageProducts
                };


                //UNCOMMENT FOR PRODUCTION
                if (!resp.data.event_fired) {
                    this.sendWebEngagePaymentData('purchase', webEngageData);
                    if (resp.data.newCustomer) {
                        this.sendWebEngagePaymentData('first_purchase', webEngageData);
                    }

                    // new Tracker("BUY", resp.data.payment.userId, resp.data.payment.cart_id, resp.data.payment.cart_amount, currency).dispatch();
                    this.paydata = {
                        event: 'eec.purchase',
                        ecommerce
                        // noninteraction: false // Optional
                    };
                    this.$gtm.trackEvent(this.paydata);
                }
                // this.transactionClass = 'green--text';
                // this.transactionRefId = resp.data.ref_id;
                // this.message = this.successTitle;
                this.validOrder = true;

            } else if (resp.data.msg == 'invalid payment') {
                // this.btn_id = 'ga_purchase_failed';
                //   this.transactionClass = 'red--text';
                //   this.transactionRefId = null;
                //   this.message = this.failedTitle;
                //   this.validOrder = false;
            }
        },
        sendWebEngagePaymentData(eventName, data) {
            // console.log(eventName, data, 'done');
            if (window.webengage) {
                window.webengage.track(eventName, data);
            }
        },


    },
}